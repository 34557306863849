import { logger } from 'core/logger';
import { cookies } from 'core/utils';
import { auth as authService } from 'services';
import { COOKIE_KEY_APP_ATM_TOKEN, COOKIE_KEY_APP_ATM_TOKEN_EXP } from 'core/constants';

let token;
let tokenExpiredAt;

export const fetchToken = async () => {
  try {
    // Cache
    token = cookies.getKey(COOKIE_KEY_APP_ATM_TOKEN);
    tokenExpiredAt = cookies.getKey(COOKIE_KEY_APP_ATM_TOKEN_EXP);
    logger.debug('GET TOKEN >>>> Expired Time', tokenExpiredAt);
    let diffMins = 0;
    if (tokenExpiredAt) {
      const today = new Date();
      const expDate = new Date(tokenExpiredAt);
      const diffMs = expDate - today; // milliseconds between now & Christmas
      diffMins = Math.round(diffMs / 1000 / 60); // minutes
    }
    if (token && diffMins >= 2) return token;
    logger.debug('Renew Security Token');
    const securityToken = await authService.renewSecurityToken();
    const { atmToken, atmTokenExpiresAt } = securityToken;
    if (atmToken) {
      token = atmToken;
      cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN, token);
      cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN_EXP, atmTokenExpiresAt);
      return token;
    }

    return null;
  } catch (err) {
    logger.debug(err);
    if (
      err &&
      err.response &&
      err.response.data &&
      (err.response.data.code === 'token-expired' ||
        err.response.data.code === 'invalid-token' ||
        err.response.data.code === 'token-is-required')
    ) {
      // This error occurs when user token is expired or invalid or empty
      throw err;
    }
    return null;
  }
};
