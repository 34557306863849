import { cookies } from 'core/utils';
import { COOKIE_KEY_USER_TOKEN } from 'core/constants';
import React from 'react';
import { logger } from 'core/logger';
import { useLocation } from 'react-router-dom';

function useWebChat() {
  const location = useLocation();

  React.useEffect(() => {
    const userToken = cookies.getKey(COOKIE_KEY_USER_TOKEN);
    const channelId = userToken
      ? process.env.REACT_APP_WEBCHAT_LOGGED_CHANNEL_ID
      : process.env.REACT_APP_WEBCHAT_NOT_LOGGED_CHANNEL_ID;

    if (window.wcChannelId === channelId) {
      return;
    }

    if (window.wcChannelId && window.wcChannelId !== channelId) {
      // find all element from body with property channel_id then remove them
      const elements = document.querySelectorAll(`[channel_id="${window.wcChannelId}"]`);
      elements.forEach(element => {
        element.remove();
      });
    }

    window.wcChannelId = channelId;

    if (!window.sgAsyncInit) {
      window.sgAsyncInit = function() {
        window.SG.init({
          channelId,
          visible: true
        });

        window.SG.addListenerOnce('state_changed', function(state) {
          if (state === 'connected') {
            logger.debug(`WEBCHAT CONNECTED TO CHANNEL ${window.wcChannelId}`);
          }
        });
      };

      function injectWebchatSDK(d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        const js = d.createElement(s);
        js.id = id;
        js.src = 'https://chatbox.c-plus.cloud/plugin/sdk.js';
        js.nonce = process.env.REACT_APP_RANDOME_NUMBER;
        fjs.parentNode.insertBefore(js, fjs);
      }

      injectWebchatSDK(document, 'script', 'sg-jssdk');
    } else {
      window.SG.init({
        channelId,
        visible: true
      });
    }
  }, [location.pathname]);
}

export default useWebChat;
