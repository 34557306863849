import React, { useEffect, useState } from 'react';
import { BaseInput, BaseLabelCounter } from 'components';
import { MFA_METHODS } from 'core/constants';
import { Form, FormGroup, FormFeedback, Button, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { logger } from 'core/logger';
import { user as userService, resource as resourceService } from 'services';
import { errorUtils } from 'core/utils';
import 'assets/scss/theme/login.scss';

function MFA(props) {
  const { t } = useTranslation();
  const {
    updateFullLoading,
    handleCancel,
    handleBack,
    processInfo,
    loginIdStore,
    updateNotification,
    updateUser,
    history
  } = props;
  const [otp, setOTP] = useState('');
  const [errorOTP, setErrorOTP] = useState('');
  const [countDown, setCountDown] = useState(0);
  let timer = null;

  const checkCountDown = () => {
    const selectedMethod = localStorage.getItem('selectedMethod');
    if (MFA_METHODS.AUTHENTICATOR === selectedMethod) return;
    let ttl = 0;
    let seconds = 0;
    if (MFA_METHODS.SMS === selectedMethod) {
      ttl = localStorage.getItem('sendSmsOtpCodeTimestamp');
    } else if (MFA_METHODS.EMAIL === selectedMethod) {
      ttl = localStorage.getItem('sendEmailOtpCodeTimestamp');
    }
    if (ttl) {
      try {
        seconds = parseInt(ttl) - Math.floor(new Date().getTime() / 1000) + 30;
      } catch (error) {
        removeOtpCodeTimestamp(selectedMethod);
      }
    }
    setCountDown(seconds);
    if (seconds <= 0) removeOtpCodeTimestamp(selectedMethod);
  };

  const removeOtpCodeTimestamp = mfaMethod => {
    if (MFA_METHODS.SMS === mfaMethod) {
      localStorage.removeItem('sendSmsOtpCodeTimestamp');
    } else if (MFA_METHODS.EMAIL === mfaMethod) {
      localStorage.removeItem('sendEmailOtpCodeTimestamp');
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [countDown]);

  useEffect(() => {
    // reset mfaRetry
    localStorage.removeItem('mfaRetry');
  }, []);

  useEffect(() => {
    const selectedMethod = localStorage.getItem('selectedMethod');
    if ([MFA_METHODS.SMS, MFA_METHODS.EMAIL].includes(selectedMethod) && loginIdStore) {
      checkCountDown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOTPChange = value => {
    let err = '';
    if (!value) {
      err = 'common.validator.isRequired';
    }
    setOTP(value);
    setErrorOTP(err);
    return !err;
  };

  const handleVerifyOTP = async e => {
    e.preventDefault();
    try {
      updateFullLoading(true);
      // validate otp
      onOTPChange(otp);
      if (!onOTPChange(otp)) {
        toast.warning(t('common.message.invalidInputs'));
        return;
      }
      // verify otp
      await userService.verifyOTPChangeMFA({
        otp,
        loginId: loginIdStore,
        otpPhase: processInfo.otpPhase,
        otpMethod: processInfo.otpMethod,
        contactEmail: processInfo.contactEmail,
        mobilePhone: processInfo.mobilePhone,
        mobileCountryCode: processInfo.mobileCountryCode
      });
      const selectedMethod = localStorage.getItem('selectedMethod');
      // callback
      handleBack(selectedMethod);
      // display resource ( case: setup MFA in Policy )
      const resources = await resourceService.getResources();
      props.addResources({ initialized: true, data: resources });
    } catch (err) {
      console.log('🚀 handleVerifyOTP -> error -> ', err);
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      switch (code) {
        case 'user-not-found':
          toast.warning(t('common.message.user.notFound'));
          break;
        case 'resource-not-found':
          toast.warning(t('common.message.resource.notFound'));
          break;
        case 'generate-otp-error':
          toast.warning(t('mfa.message.generate.otp.error'));
          break;
        case 'invalid-otp':
          toast.warning(t('mfa.message.invalid.otp'));
          let mfaRetry = localStorage.getItem('mfaRetry') || 0;
          try {
            const count = parseInt(mfaRetry);
            if (count >= 5) {
              handleCancel();
            } else {
              localStorage.setItem('mfaRetry', count + 1);
            }
          } catch (error) {
            logger.error(error);
            handleCancel();
          }
          break;
        case 'otp-expired':
          toast.warning(t('mfa.message.otp.expired'));
          break;
        case 'too-many-sms':
          toast.warning(t('mfa.message.too.many.sms'));
          break;
        case 'country-not-support-sms':
          toast.warning(t('common.message.mobile.not.support.country'));
          break;
        default:
          errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const handleResendOTP = async e => {
    e.preventDefault();
    try {
      updateFullLoading(true);
      let mfaRetry = localStorage.getItem('mfaRetry') || 0;
      try {
        mfaRetry = parseInt(mfaRetry);
        if (mfaRetry > 4) {
          handleCancel();
          return;
        }
      } catch (error) {
        logger.error(error);
        mfaRetry = 0;
      }
      await userService
        .generateTwoStepAuthenticationOTP({
          lng: localStorage.getItem('sysLanguage') || 'en_US',
          loginId: loginIdStore,
          otpPhase: processInfo.otpPhase,
          otpMethod: processInfo.otpMethod,
          contactEmail: processInfo.contactEmail,
          mobilePhone: processInfo.mobilePhone,
          mobileCountryCode: processInfo.mobileCountryCode
        })
        .catch(error => {
          const response = error.response || {};
          if (response.status === 429) {
            const data = response.data || {};
            if (data.code === 'too-many-sms') {
              localStorage.setItem(loginIdStore, data.ttl);
              checkCountDown(loginIdStore);
            }
          }
          throw error;
        });
      const ttl = Math.floor(new Date().getTime() / 1000);
      if (MFA_METHODS.SMS === processInfo.otpMethod) {
        toast.success(t('mfa.message.sentSMS.ok'));
        localStorage.setItem('sendSmsOtpCodeTimestamp', ttl);
      } else {
        toast.success(t('mfa.message.sentMail.ok'));
        localStorage.setItem('sendEmailOtpCodeTimestamp', ttl);
      }
      localStorage.setItem('mfaRetry', mfaRetry + 1);
      checkCountDown();
    } catch (err) {
      logger.error('handleResendOTP -> err', err);
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      switch (code) {
        case 'user-not-found':
          toast.warning(t('common.message.user.notFound'));
          break;
        case 'generate-otp-error':
          toast.warning(t('mfa.message.generate.otp.error'));
          break;
        case 'too-many-sms':
          toast.warning(t('mfa.message.too.many.sms'));
          break;
        case 'country-not-support-sms':
          toast.warning(t('common.message.mobile.not.support.country'));
          break;
        default:
          errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
      }
    } finally {
      updateFullLoading(false);
    }
  };

  return (
    <Form inline method="post" className="p-t-15" action="#" autoComplete="off" onSubmit={handleVerifyOTP}>
      <Col>
        <FormGroup>
          <Col md={3}>
            <BaseLabelCounter className="text-md-right" label={t('mfa.otp.label')} value={otp} length={6} />
          </Col>
          <Col md={7} className={'mfa-sms-form-step2'}>
            <BaseInput
              placeholder={t('placeholder.input.Enter', { field: t('mfa.otp.label') })}
              value={otp}
              onChange={onOTPChange}
              maxLength={6}
              customizeRegex={{ otp: /^[0-9０-９]*$/ }}
            />
          </Col>
        </FormGroup>
        <Col md={{ size: 7, offset: 3 }}>
          <FormFeedback className="d-block">{errorOTP ? t(errorOTP, { label: t('mfa.otp.label') }) : ''}</FormFeedback>
        </Col>
        <Col md={{ size: 7, offset: 3 }} className="inline" style={{ paddingLeft: '8px', paddingTop: '10px' }}>
          <Button color="primary" variant="contained" className="mr-1 xs-full-width" onClick={handleVerifyOTP}>
            {t('mfa.button.otp.verify')}
          </Button>{' '}
          {MFA_METHODS.AUTHENTICATOR !== processInfo.otpMethod && (
            <Button
              color="default"
              variant="contained"
              className="mr-1 xs-full-width xs-m-t-5"
              disabled={countDown > 0}
              onClick={handleResendOTP}
            >
              {countDown > 0 ? t('mfa.button.otp.resend') + ' ' + countDown : t('mfa.button.otp.resend')}
            </Button>
          )}{' '}
          <Button color="default" variant="contained" className="xs-full-width xs-m-t-5" onClick={handleCancel}>
            {t('common.button.close')}
          </Button>
        </Col>
      </Col>
    </Form>
  );
}
export default MFA;
